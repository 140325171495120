<template>
  <div class="preview">
   <suggest :is_preview="1"></suggest>
  </div>
</template>

<script>
import suggest from '../collect/suggest.vue'
export default {
  components:{
    suggest
  }
}
</script>

<style>

</style>